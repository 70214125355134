import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState,Fragment } from "react";
import Loader from "../BaseFile/comman/Loader";
import {  getAllDepositeByid} from "../redux/depositeSlice";
import { getAllWithdrawalByid} from "../redux/withdrawalSlice";
import { ArrowUpCircleIcon,} from '@heroicons/react/20/solid'
const statuses = {
    pending: 'text-green-700 bg-green-50 ring-green-600/20',
    complete: 'text-gray-600 bg-gray-50 ring-gray-500/10',
    inprogress: 'text-red-700 bg-red-50 ring-red-600/10',
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
export default function UserTransaction() {
  const dispatch = useDispatch();
  const { singleDeposite, loading, error, message } = useSelector(
    (state) => state.alldeposite
  );  
  const { singleWithdrawal } = useSelector(
    (state) => state.allwithdrawal
  );

  const { auth } = useSelector((state) => state.auth);

  useEffect(()=>{
    if(auth?.id){
        dispatch(getAllDepositeByid(auth?.id))
        dispatch(getAllWithdrawalByid(auth?.id))
      };
  },[auth?.id])
  let combinedArray = [];

  const depositsWithType = singleDeposite?.map(deposit => ({ ...deposit, type: 'deposit' })) || [];
  const withdrawalsWithType = singleWithdrawal?.map(withdrawal => ({ ...withdrawal, type: 'withdrawal' })) || [];
  
  if (withdrawalsWithType.length > 0) {
    combinedArray = [...depositsWithType, ...withdrawalsWithType];
    combinedArray.sort((a, b) => new Date(a.createdAT) - new Date(b.createdAT));
  }
  
  console.log(combinedArray); // Ensure combinedArray is populated correctly
  
  
return(
    <>
    {loading ? (
        <Loader />
      ) : (
        <div className="overflow-hidden border-t border-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <table className="w-full text-left">
                 
                    <tbody>
                    <div className=" overflow-hidden border-t border-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <table className="w-full text-left">
                    <thead className="sr-only">
                      <tr>
                        <th>Amount</th>
                        <th className="hidden sm:table-cell">Client</th>
                        <th>More details</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                          {combinedArray?.slice(-8).reverse().map((transaction) => (
                            <tr key={transaction.id}>
                              <td className="relative py-1 pr-6">
                                <div className="flex gap-x-6">
                                  <ArrowUpCircleIcon
                                    aria-hidden="true"
                                    className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                                  />
                                  <div className="flex-auto">
                                    <div className="flex items-start gap-x-3">
                                      <div className="text-sm font-medium leading-6 text-gray-900">
                                        ${transaction?.amount} 
                                      </div>
                                      <div
                                        className={classNames(
                                          statuses[transaction?.status],
                                          'rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
                                        )}
                                      >
                                        {transaction?.status}
                                      </div>
                                    </div>
                                    
                                  </div>
                                </div>
                                <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                              </td>
                              <td className="hidden py-2 pr-6 sm:table-cell capitalize">
                                <div className="text-sm leading-6 text-gray-900">{transaction?.type}</div>
                                <div
                                className={`mt-1 text-xs leading-5 p-2 rounded text-white ${transaction?.type === 'deposit' ? 'bg-green-600' : 'bg-red-600'}`}
                                >
                                {transaction?.type} By you at {new Date(transaction?.createdAT).toLocaleString()}
                                </div>

                              </td>
                              <td className="py-5 text-right">
                                <div className="flex justify-end">
                                  <a
                                    href={transaction.href}
                                    className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500"
                                  >
                                    View<span className="hidden sm:inline"> transaction</span>
                                    <span className="sr-only">
                                      , invoice #{transaction.invoiceNumber}, {transaction.client}
                                    </span>
                                  </a>
                                </div>
                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                  Invoice <span className="text-gray-900">#{transaction.invoiceNumber}</span>
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
      )
    }</>
)}