import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Loader from "../BaseFile/comman/Loader";
import { AiFillDelete } from "react-icons/ai";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import { Confirmation } from "../BaseFile/comman/Confirmation";
import Spinner from "../BaseFile/comman/Spinner";
import {
  addWithdrawal,
  getAllWithdrawalByid,
  deleteWithdrawal,
  clearErrors,
  clearMessage,
} from "../redux/withdrawalSlice";


export default function UserAddWithdrawal() {
  const dispatch = useDispatch();
  const { singleWithdrawal, loading, error, message } = useSelector(
    (state) => state.allwithdrawal
  );
  const { auth } = useSelector((state) => state.auth);
  const [values, setValues] = useState({});
  const [deleteID, setDeleteID] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
      if (error) {
        const errorInterval = setInterval(() => {
          dispatch(clearErrors());
        }, 3000);
        return () => clearInterval(errorInterval);
      }
      if (message) {
        const messageInterval = setInterval(() => {
          dispatch(clearMessage());
        }, 3000);
        return () => clearInterval(messageInterval);
      }
  }, [dispatch, error, message]);

  useEffect(()=>{
    if(auth?.id){
        dispatch(getAllWithdrawalByid(auth?.id))
      };
  },[auth?.id])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    const form = e.target.closest("form");
    if (form.checkValidity()) {
      const allValues = {
        ...values,
        user_id: auth?.id,
      };
      console.log(allValues);
      dispatch(addWithdrawal({ values: allValues }));
    } else {
      form.reportValidity();
    }
  };
  const handleDelete = (id) => {
    setDeleteID(id);
    setModalOpen(true);
  };

  const isClose = () => {
    setModalOpen(false);
  };
  console.log(error)
  return (
    <>
      <div className="bg-gray-100">
        <div className="w-full mx-auto bg-white rounded shadow overflow-hidden">
          {message && <SuccessAlert message={message} />}
          {error && <ErrorAlert error={error} />}
          <div className="px-6 py-4">
            <h2 className="text-xl font-semibold mb-5 text-gray-800 mb-2">
              Withdraw Request
            </h2>
            <form className="flex justify-between">
              <div className="grid w-1/3 gap-4">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Amount
                  </label>
                  <input
                    type="number"
                    name="amount"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="flex justify-end mt-4">
                <button
                  type="submit"
                  className="px-4  bg-indigo-500 text-white rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
                  onClick={handleSaveChanges}
                >
                {loading ? <Spinner /> : "Send Withdrawal Request"}

                </button>
              </div>
            </form>
          </div>
        </div>

        <div className={`${loading ? "h-[260px] items-center" : "h-full"}`}>
          {loading ? (
            <Loader />
          ) : (
    <div className="bg-gray-900 ">

            <div className=" flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <h1 className="text-white text-2xl font-semibold m-8">Withdrawal History</h1>
                  <table className="z-10 mt-6 w-full whitespace-nowrap text-left">
                    <thead className="border-b border-white/10 text-sm leading-6 text-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-2 pl-4 font-semibold sm:pl-6 lg:pl-8"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell"
                        >
                          amount
                        </th>
                        <th
                          scope="col"
                          className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                        >
                          Request / action at
                        </th>
                        <th
                          scope="col"
                          className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-white/5">
                    {singleWithdrawal?.slice().reverse().map((item, index) => (
                        <tr key={index}>
                          <td className="py-4 pl-4 sm:pl-6 lg:pl-8">
                            <div className="flex items-center">
                              <div className="truncate text-sm font-medium leading-6 text-white">
                                {item?.id}
                              </div>
                            </div>
                          </td>
                          <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                            <div className="">
                              <div className="font-mono text-sm leading-6 text-gray-400">
                                ${item?.amount}
                              </div>
                            </div>
                          </td>
                          <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                            <div className="">
                              <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                                {item?.status}
                              </div>
                            </div>
                          </td>
                          <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                            <div className="">
                              <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                                {item?.createdAT}/{item?.acceptat}
                              </div>
                            </div>
                          </td>

                          <td className="py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                            <div className="z-0 flex space-x-4">
                              <>
                                <AiFillDelete
                                  className="h-4 w-4 text-red-400 cursor-pointer"
                                  onClick={() => handleDelete(item?.id)}
                                  title="Delete"
                                />
                              </>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            </div>
          )}
        </div>

        {modalOpen && (
          <Confirmation
            isClose={isClose}
            deletefunction={deleteWithdrawal}
            id={deleteID}
          />
        )}
      </div>
    </>
  );
}
