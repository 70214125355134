import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../comman/Spinner";
import { signupUser, clearErrors, clearMessage } from "../../redux/authSlice";
import ErrorAlert from "../comman/ErrorAlert";
import SuccessAlert from "../comman/SuccessAlert";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
export default function Registration() {
  const [referralCode, setReferralCode] = useState(null);
  const query = useQuery();

  useEffect(() => {
    const referral = query.get('referral');
    console.log(referral);
    if (referral) {
      setReferralCode(referral);
    }
  }, [query]);

  const [showPass, setShowPass] = useState(false);
  const { loading, error, message, auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    referralBy: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Incorrect email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().required("confirm password is required"),
    username: Yup.string().required("username is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if(referralCode){
        values.referralBy = referralCode
      }
      dispatch(signupUser(values));
    },
  });

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        dispatch(clearErrors());
      }, 2000);

      return () => clearTimeout(timer);
    }
    if (message) {
      const timer = setTimeout(() => {
        navigate('/')
        dispatch(clearMessage());
      }, 1000);

      return () => clearTimeout(timer);
    }

  
  }, [error, dispatch, message, formik]);

  return (
    <>
      <>
        <div className="md:flex regbg md:p-8 p-2 md:py-12 md:px-12">
          <div className=" h-auto md:w-1/2 justify-center py-4 bg-gray-700 bg-opacity-10 rounded-2xl shadow-2xl">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img
                className="mx-auto h-10 w-auto"
                src="logo.png"
                alt="Your Company"
              />
              <h2 className="mt-10 text-center text-2xl text-gray-700 font-bold leading-9 tracking-tight text-gray-700">
                Sign Up Here
              </h2>
            </div>
            <div className="sm:mx-auto sm:w-full px-12">
              <form className="space-y-2 " onSubmit={formik.handleSubmit}>
                <div className="md:flex gap-x-4 justify-between ">
                  <div className="w-full">
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-gray-700"
                    >
                      Username
                    </label>
                    <div className="mt-2">
                      <input
                        id="username"
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    {formik.touched.username && formik.errors.username && (
                      <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
                        {formik.errors.username}*
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-700"
                    >
                      email
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="email"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    {formik.touched.email && formik.errors.email && (
                      <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
                        {formik.errors.email}*
                      </p>
                    )}
                  </div>
                </div>
                <div className="md:flex gap-x-4 justify-between ">
                  <div className="w-full">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    {formik.touched.password && formik.errors.password && (
                      <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
                        {formik.errors.password}*
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="confirmPassword"
                        className="block text-sm font-medium leading-6 text-gray-700"
                      >
                        confirm Password
                      </label>
                    </div>
                    <div className="mt-2">
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type={showPass ? "text" : "password"}
                        autoComplete="current-password"
                        required
                        className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      <span
                        onClick={() => setShowPass(!showPass)}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-600 cursor-pointer"
                      >
                        {/* {!showPass ? (
                        <FaRegEye
                          className="h-6 w-6 text-gray-600"
                          aria-hidden="true"
                        />
                      ) : (
                        <FaRegEyeSlash
                          className="h-6 w-6 text-gray-600"
                          aria-hidden="true"
                        />
                      )} */}
                      </span>
                    </div>
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword && (
                        <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
                          {formik.errors.confirmPassword}*
                        </p>
                      )}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="referralBy"
                    className="block text-sm font-medium leading-6 text-gray-700"
                  >
                    Referral Code (Optional)
                  </label>
                  <div className="mt-2">
                    <input
                      id="referralBy"
                      name="referralBy"
                      value={formik.values.referralBy}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 px-4  text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {formik.touched.referralBy && formik.errors.referralBy && (
                    <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
                      {formik.errors.referralBy}*
                    </p>
                  )}
                </div>

                {error && <ErrorAlert error={error} />}
                {message && <SuccessAlert error={message} />}
                <div>
                  <button
                    type="submit"
                    className={`flex w-full uppercase tracking-widest justify-center rounded ${
                      loading ? "bg-indigo-200" : "bg-indigo-600"
                    } px-3 py-1.5 px-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  >
                    {loading ? <Spinner /> : "Registration"}
                  </button>
                </div>
              </form>

              <p className="mt-4 text-center text-gray-700 text-sm ">
                Already a member?{" "}
                <Link
                  to="/Login"
                  className="font-semibold leading-6 text-gray-700"
                >
                  Login Here
                </Link>
              </p>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
