import { Children, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signoutuser } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  MenuItem,
  MenuItems,
  TransitionChild,
} from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

const Management = [
  { name: "Unblocked Users", to: "/admin/user/unblock", current: true },
  { name: "Blocked User", to: "/admin/user/block", current: false },
  { name: "Active Member", to: "/admin/user/active", current: false },
  { name: "Inactive Member", to: "/admin/user/inactive", current: false },
  { name: "All User", to: "/admin/user/all", current: false },
];
const Requests = [
  {
    name: "Withdrawal",
    to: "/admin/pendingwithdrawalrequest",
    current: true,
  },
  { name: "Deposite", to: "/admin/deposite", current: false },
  { name: "TopUp", to: "/admin/topup", current: false },
];
const plans = [
  { name: "Plans", to: "/admin/membership/plan", current: true },
  { name: "Add Plan", to: "/admin/addplan", current: true },

];

const MainMenu = [
  {
    name: "Dashboard",
    to: "/admin/dashboard",
    icon: HomeIcon,
    current: true,
    submenu: [],
  },
  { name: "Refferals",to: "", icon: UsersIcon, current: false, submenu: [] },
  {
    name: "Membership Plans",
    to: "/admin/membership/plan",
    icon: FolderIcon,
    current: false,
    submenu: plans,
  },
  {
    name: "Reports",
    to: "/admin/reports",
    icon: CalendarIcon,
    current: false,
    submenu: [],
  },
  {
    name: "Support",
    to: "/admin/support",
    icon: DocumentDuplicateIcon,
    current: false,
    submenu: [],
  },
  {
    name: "QR setting",
    to: "/admin/qr/Link",
    icon: DocumentDuplicateIcon,
    current: false,
    submenu: [],
  },
];
const User_Interface = [
  {
    id: 1,
    name: "Management",
    to: "/admin/user/all",
    initial: "H",
    current: false,
    submenu: Management,
  },
  {
    id: 2,
    name: "Rewards",
    to: "/admin/rewards",
    initial: "T",
    current: false,
    submenu: [],
  },
  {
    id: 3,
    name: "Transactions",
    to: "/admin/pendingwithdrawalrequest",
    initial: "W",
    current: false,
    submenu: Requests,
  },
  {
    id: 3,
    name: "Income",
    to: "/admin/income",
    initial: "W",
    current: false,
    submenu: [],
  },
];
const Settings = [
  { id: 1, name: "Email", to: "#", initial: "H", current: false, submenu: [] },
  {
    id: 2,
    name: "General",
    to: "/admin/settings",
    initial: "T",
    current: false,
    submenu: [],
  },
  {
    id: 3,
    name: "News & Events",
    to: "#",
    initial: "W",
    current: false,
    submenu: [],
  },
];

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AdminMenu({ Children, PageName }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [headerMenu, setHeaderMenu] = useState([]);

  function handleHeaderMenu(item) {
    setHeaderMenu(item);
  }
  function handlesignout() {
    dispatch(signoutuser());
    navigate("/admin/login");
  }
  const userNavigation = [
    { name: "Your Profile", to: `/user/profile/${auth?.id}` },
    { name: "Settings", to: "#" },
  ];
  return (
    <>
      <div>
        <div>
          <Dialog
            open={sidebarOpen}
            onClose={setSidebarOpen}
            className="relative z-50 lg:hidden"
          >
            <DialogBackdrop
              transition
              className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
            />

            <div className="fixed inset-0 flex">
              <DialogPanel
                transition
                className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
              >
                <TransitionChild>
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                    <button
                      type="button"
                      onClick={() => setSidebarOpen(false)}
                      className="-m-2.5 p-2.5"
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        aria-hidden="true"
                        className="h-6 w-6 text-white"
                      />
                    </button>
                  </div>
                </TransitionChild>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex no-scrollbar overflow-auto grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      alt="Your Company"
                      src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                      className="h-8 w-auto"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {MainMenu.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={
                                  item?.name == "Refferals"
                                    ? `/admin/refferaltable/${item?.refferal_code}`
                                    : item?.to
                                }
                                onClick={() => handleHeaderMenu(item?.submenu)}
                                className={classNames(
                                  item?.current
                                    ? "bg-gray-800 text-white"
                                    : "text-gray-400 hover:bg-gray-800 hover:text-white",
                                  "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                )}
                              >
                                <item.icon
                                  aria-hidden="true"
                                  className="h-6 w-6 shrink-0"
                                />
                                {item?.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">
                          User Interface
                        </div>
                        <ul role="list" className="-mx-2 mt-2 space-y-1">
                          {User_Interface.map((team) => (
                            <li key={team.name}>
                              <Link
                                onClick={() => handleHeaderMenu(team.submenu)}
                                to={team.to}
                                className={classNames(
                                  team.current
                                    ? "bg-gray-800 text-white"
                                    : "text-gray-400 hover:bg-gray-800 hover:text-white",
                                  "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                )}
                              >
                                <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                  {team.initial}
                                </span>
                                <span className="truncate">{team.name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">
                          Settings
                        </div>
                        <ul role="list" className="-mx-2 mt-2 space-y-1">
                          {Settings.map((team) => (
                            <li key={team.name}>
                              <Link
                                onClick={() => handleHeaderMenu(team.submenu)}
                                to={team.to}
                                className={classNames(
                                  team.current
                                    ? "bg-gray-800 text-white"
                                    : "text-gray-400 hover:bg-gray-800 hover:text-white",
                                  "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                )}
                              >
                                <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                  {team.initial}
                                </span>
                                <span className="truncate">{team.name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </DialogPanel>
            </div>
          </Dialog>

          <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <div className="no-scrollbar overflow-auto pb-10 flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
              <div className="flex h-16 shrink-0 items-center">
                <img
                  alt="Your Company"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  className="h-8 w-auto"
                />
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {MainMenu.map((item) => (
                        <li key={item?.name}>
                          <Link
                            to={
                              item?.name === "Refferals"
                              ? `/admin/refferaltable/${auth?.refferal_code}`
                              : item.to
                            }
                            onClick={() => setHeaderMenu(item.submenu)}
                            className={classNames(
                              item.current
                                ? "bg-gray-800 text-white"
                                : "text-gray-400 hover:bg-gray-800 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                            )}
                          >
                            <item.icon
                              aria-hidden="true"
                              className="h-6 w-6 shrink-0"
                            />
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <div className="text-xs font-semibold leading-6 text-gray-400">
                      User Interface
                    </div>
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                      {User_Interface.map((team) => (
                        <li key={team.name}>
                          <Link
                            onClick={() => setHeaderMenu(team.submenu)}
                            to={team.to}
                            className={classNames(
                              team.current
                                ? "bg-gray-800 text-white"
                                : "text-gray-400 hover:bg-gray-800 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                            )}
                          >
                            <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                              {team.initial}
                            </span>
                            <span className="truncate">{team.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <div className="text-xs font-semibold leading-6 text-gray-400">
                      Settings
                    </div>
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                      {Settings.map((team) => (
                        <li key={team.name}>
                          <Link
                            onClick={() => setHeaderMenu(team.submenu)}
                            to={team.to}
                            className={classNames(
                              team.current
                                ? "bg-gray-800 text-white"
                                : "text-gray-400 hover:bg-gray-800 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                            )}
                          >
                            <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                              {team.initial}
                            </span>
                            <span className="truncate">{team.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="lg:pl-72 ">
          <div className="min-h-full">
            <div className="lg:fixed w-full z-50">
              <Disclosure as="nav" className="bg-gray-800 ">
                <div className=" max-w-[170vh] ml-4">
                  <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center">
                      <div className="hidden md:block">
                        <div className=" flex items-baseline space-x-4">
                          {headerMenu?.map((item) => (
                            <Link
                              key={item.name}
                              to={item.to}
                              aria-current={item.current ? "page" : undefined}
                              className={classNames(
                                item.current
                                  ? "bg-gray-900 text-white"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "rounded-md px-3 py-2 text-sm font-medium"
                              )}
                            >
                              {item.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        <button
                          type="button"
                          className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">View notifications</span>
                          <BellIcon aria-hidden="true" className="h-6 w-6" />
                        </button>

                        <Menu as="div" className="relative ml-3">
                          <div>
                            <MenuButton className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="absolute -inset-1.5" />
                              <span className="sr-only">Open user menu</span>
                              <img
                                alt=""
                                src={user.imageUrl}
                                className="h-8 w-8 rounded-full"
                              />
                            </MenuButton>
                          </div>
                          <MenuItems
                            transition
                            className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                          >
                            <MenuItem>
                              <Link
                                to={`/admin/check/profile/${auth?.id}`}
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                              >
                                {auth?.username}
                              </Link>
                            </MenuItem>
                            <MenuItem>
                              <button
                                onClick={handlesignout}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                              >
                                Logout
                              </button>
                            </MenuItem>
                          </MenuItems>
                        </Menu>
                      </div>
                    </div>
                    <div className="-mr-2 flex justify-between w-full md:hidden">
                      <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 bg-gray-800 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                        <button
                          type="button"
                          onClick={() => setSidebarOpen(true)}
                          className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
                        >
                          <span className="sr-only">Open sidebar</span>
                          <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                      <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon
                          aria-hidden="true"
                          className="block h-6 w-6 group-data-[open]:hidden"
                        />
                        <XMarkIcon
                          aria-hidden="true"
                          className="hidden h-6 w-6 group-data-[open]:block"
                        />
                      </DisclosureButton>
                    </div>
                  </div>
                </div>

                <DisclosurePanel className="md:hidden">
                  <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                    {headerMenu?.map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        to={item.to}
                        aria-current={item.current ? "page" : undefined}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "block rounded-md px-3 py-2 text-base font-medium"
                        )}
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </div>
                  <div className="border-t border-gray-700 pb-3 pt-4">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <img
                          alt=""
                          src={user.imageUrl}
                          className="h-10 w-10 rounded-full"
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white">
                          {user.name}
                        </div>
                        <div className="text-sm font-medium leading-none text-gray-400">
                          {user.email}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        <BellIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      {userNavigation.map((item) => (
                        <DisclosureButton
                          key={item.name}
                          as="a"
                          to={item.to}
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                          {item.name}
                        </DisclosureButton>
                      ))}
                      <DisclosureButton
                        onClick={handlesignout}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        Logout
                      </DisclosureButton>
                    </div>
                  </div>
                </DisclosurePanel>
              </Disclosure>

              <header className="bg-white">
                <div className=" max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                  <nav
                    aria-label="Breadcrumb"
                    className="flex border-b border-gray-200 bg-white"
                  >
                    <div className="flex items-center">
                      <svg
                        fill="currentColor"
                        viewBox="0 0 24 44"
                        preserveAspectRatio="none"
                        aria-hidden="true"
                        className="h-full w-6 flex-shrink-0 text-gray-200"
                      >
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                      </svg>
                      Admin / {PageName}
                    </div>
                  </nav>
                </div>
              </header>
            </div>
            <main className="lg:pt-36 -z-10">
              <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                {Children}
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
