import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Loader from "../BaseFile/comman/Loader";
import { getTransactionById ,clearErrors,clearMessage } from "../redux/transactionSlice";
export default function UserIncomeTransaction() {
  const { table_name } = useParams();
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state.auth);
  const { transaction, loading,error,message } = useSelector((state) => state.transaction);
  useEffect(() => {
    if (table_name && auth?.id) {
      const user_id = auth?.id;
      dispatch(getTransactionById({ table_name, user_id }));
    }
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, table_name, auth?.id]);
  return (
    <div className={`${loading ? "h-[260px] items-center" : "h-full"}`}>
    {loading ? (
      <Loader />
    ) : (
      <div className="bg-gray-900 ">
        <div className=" flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <h1 className="text-white text-2xl font-semibold m-8">
                Transaction History
              </h1>
              <table className="z-10 mt-6 w-full whitespace-nowrap text-left">
                <thead className="border-b border-white/10 text-sm leading-6 text-white">
                  <tr>
                    <th
                      scope="col"
                      className="py-2 pl-4 font-semibold sm:pl-6 lg:pl-8"
                    >
                      id
                    </th>
                    <th
                      scope="col"
                      className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                    >
                      On Amount
                    </th>
                    <th
                      scope="col"
                      className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                    >
                      Percent
                    </th>
                    <th
                      scope="col"
                      className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                    >
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-white/5">
                  {transaction
                    ?.slice()
                    .reverse()
                    .map((item, index) => (
                      <tr key={index}>
                        <td className="py-4 pl-4 sm:pl-6 lg:pl-8">
                          <div className="flex items-center">
                            <div className="truncate text-sm font-medium leading-6 text-white">
                              {item?.id}
                            </div>
                          </div>
                        </td>
                        <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                          <div className="">
                            <div className="font-mono text-sm leading-6 text-gray-400">
                              ${item?.amount}
                            </div>
                          </div>
                        </td>
                        <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                          <div className="">
                            <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                              {item?.type}
                            </div>
                          </div>
                        </td>
                       
                        <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                          <div className="">
                            <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                              {item?.onamount}
                            </div>
                          </div>
                        </td>
                           
                        <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                          <div className="">
                            <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                              {item?.percent}
                            </div>
                          </div>
                        </td>   
                        <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                          <div className="">
                            <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                              {item?.createdAt}
                            </div>
                          </div>
                        </td>

                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
  );
}
