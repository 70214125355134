import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrView } from "react-icons/gr";
import { useParams } from "react-router-dom";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import { Confirmation } from "../BaseFile/comman/Confirmation";
import Loader from "../BaseFile/comman/Loader";
import { TbBinaryTree } from "react-icons/tb";
import {
  getAllUsers,
  clearErrors,
  deleteUsers,
  clearMessage,
} from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import AdminCashHandle from "./AdminCashHandle";

export default function AdminUserList() {
  const { action } = useParams();
  const dispatch = useDispatch();
  const { allusers, loading } = useSelector(
    (state) => state.allusers
  );
  const { message,error } = useSelector((state) => state.allwithdrawal);

  const [allUser, setAllUser] = useState(allusers);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalopen, setModalopen] = useState(false);
  const [cashHandle,setCashHandle] = useState(null)
  const [deleteID, setdeleteID] = useState();
  const [userId, setUserId] = useState();
  const [name,setName] = useState(null)
  const [balance,setBalance] = useState(null)
  const IconContainer = ({ children }) => (
    <div className="z-0 relative flex items-center justify-center">
        {children}
    </div>
  );

  useEffect(() => {
    dispatch(getAllUsers());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch,error,message,clearErrors,clearMessage]);

  useEffect(() => {
    if (action == "all") {
      setAllUser(allusers);
    } else {
      setAllUser(
        allusers?.filter((p) => p.is_active == action || p.status == action)
      );
    }
  }, [action]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setAllUser(
      allusers?.filter((p) => p.username?.toLowerCase().includes(searchTerm))
    );
    setSearchQuery(e.target.value);
  };

  function isClose() {
    setModalopen(false);
  }
  function handleDelete(id) {
    setdeleteID(id);
    if (deleteID) {
      console.log(id);
      setModalopen(true);
    }
  }
  function HandleCash(action , name ,id, balance){
    setCashHandle(action)
    setName(name)
    setUserId(id)
    setBalance(balance)
  }

  return (
    <div className="bg-gray-900 ">
      <div className="px-8 pt-2">
        <label htmlFor="email" className="sr-only">
          Search
        </label>
        <input
          id="search"
          name="search"
          value={searchQuery}
          onChange={(e) => handleSearch(e)}
          type="text"
          placeholder="search here . . ."
          className="block w-[50vh] rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}

      <div
        className={`flex justify-center ${
          loading ? "h-[560px] items-center" : "h-full"
        }`}
      >
        {loading ? (
          <Loader />
        ) : (
          <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-700">

          <thead className="border-b border-white/10 text-sm leading-6 text-white">
            <tr>
              <th scope="col" className="px-8 py-4 w-1/8 text-center text-white">
                E-mail
              </th>
              <th scope="col" className="px-8 py-4 w-1/8 text-center text-white">
                Request
              </th>
              <th scope="col" className="px-8 py-4 w-1/8 text-center text-white">
                Wallet
              </th>
              <th scope="col" className="px-8 py-4 w-1/8 text-center text-white">
                Account Status
              </th>
              <th scope="col" className="px-8 py-4 w-1/8 text-center text-white">
                Package Status
              </th>
              <th scope="col" className="px-8 py-4 w-1/8 text-center text-white">
                Created at
              </th>
              <th scope="col" className="px-8 py-4 w-1/8 text-center text-white">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-white/5">
            {((action !== 'all' || searchQuery)? allUser : allusers )?.map((item, index) => (
              <tr key={index}>
                <td className="px-8 py-4 w-1/8 text-center text-white">
                  <div className="flex items-center gap-x-4">
                    <div className="truncate text-sm font-medium leading-6 text-white w-full">
                        {item?.email}
                    </div>
                  </div>
                </td>
                <td className="px-8 flex py-4 w-1/8 text-center text-white">
                <div className="flex space-x-2">
                  <button onClick={()=>HandleCash("credit" , item?.username , item?.id , item?.business)} className="bg-green-600 text-white p-2 rounded">
                    Credit
                  </button>
                  <button onClick={()=>HandleCash("debit", item?.username , item?.id  , item?.business)} className="bg-red-600 text-white p-2 rounded">
                    Debit
                  </button>
                </div>
                </td>
                <td className="px-8 py-4 w-1/8 text-center text-white">
                  <div className="flex gap-x-3">
                    <div className="font-mono text-sm leading-6 text-gray-400 w-full">
                        {item?.business}
                    </div>
                  </div>
                </td>
                <td className="px-8 py-4 w-1/8 text-center text-white">
                  <div className="flex items-center justify-end gap-x-2 sm:justify-start w-full">
                    <div className="flex justify-center items-center gap-5 rounded-full p-1">
                     {item?.status === "block" ? (
                        <div className="flex justify-center text-white  items-center gap-5">
                          <div className="h-1.5 w-1.5 rounded-full bg-red-800" />
                          Blocked
                        </div>
                      ) : (
                        <div className="flex justify-center text-white  items-center gap-5">
                          <div className="h-1.5 w-1.5 rounded-full bg-green-800" />
                          Unblocked
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td className="px-8 py-4 w-1/8 text-center text-white">
                  <div className="flex items-center justify-end gap-x-2 sm:justify-start w-full">
                    <div className="flex justify-center items-center gap-5 rounded-full p-1">
                     {item?.is_active === "active" ? (
                        <div className="flex justify-center items-center text-white gap-5">
                          <div className="h-1.5 w-1.5 rounded-full bg-green-800" />
                          Activated
                        </div>
                      ) : (
                        <div className="flex justify-center items-center text-white gap-5">
                          <div className="h-1.5 w-1.5 rounded-full bg-red-800" />
                          Inactive
                        </div>
                      )}
                    </div>
                  </div>
                </td>
               
                <td className="px-8 py-4 w-1/8 text-center text-white">
                  {item?.created_at}
                </td>
                  <td className="px-2 py-4 flex gap-3 items-center ">
                  <Link to={`/admin/refferal/${item?.refferal_code}`}>
                        <IconContainer>
                          <TbBinaryTree
                            className="h-4 w-4 text-white cursor-pointer"
                            title="details"
                          />
                        </IconContainer>
                      </Link>
                      <Link to={`/admin/check/profile/${item?.id}`}>
                        <IconContainer>
                          <GrView
                            className="h-4 w-4 text-white cursor-pointer"
                            title="details"
                          />
                        </IconContainer>
                      </Link>
                    <IconContainer>
                      <AiFillDelete
                        className="h-4 w-4 text-red-700 cursor-pointer"
                        onClick={() => handleDelete(item?.id)}
                        title="Delete"
                      />
                    </IconContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          </div>

          </div>

        )}
      </div>
      {modalopen && (
        <Confirmation
          isClose={isClose}
          deletefunction={deleteUsers}
          id={deleteID}
        />
      )}


      {cashHandle && (
        <AdminCashHandle setCashHandle={setCashHandle} cashHandle={cashHandle} name={name} userId={userId} balance={balance}/>
      )}
    </div>
  );
}
