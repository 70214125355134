import React, { useState, useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import axios from "axios";
import {getTreeData} from "../redux/referralSlice"
// Function to generate a random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// TreeNode Component
const TreeNode = ({ user }) => {
  const [bgColor] = useState(getRandomColor()); // Generate random color once per node

  return (
    <div
      className="tree-node flex flex-col items-center p-4"
      style={{ backgroundColor: bgColor }}
    >
      <div className="flex items-center mx-8">
        <div className="relative">
          <img
            src="/default.jpg" // Placeholder image
            alt={user?.username}
            className="w-10 h-10 rounded-full border border-gray-300"
          />
          {user?.referrals && user?.referrals?.length > 0 && (
            <div className="absolute w-0.5 h-full bg-gray-300 left-1/2 transform -translate-x-1/2 top-10"></div>
          )}
        </div>
        <div className="ml-2">
          <div className="font-semibold">{user?.username}</div>
          <div>ID: {user?.id}</div>
          <div>Email: {user?.email}</div>
          <div>Status: {user?.is_active}</div>
        </div>
      </div>
      {user.referrals && user.referrals.length > 0 && (
        <div className="mt-4 flex flex-col items-center">
          <div className="flex">
            {user.referrals.map((childUser) => (
              <div key={childUser.id} className="mb-4 mx-2">
                <TreeNode user={childUser} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

// UserReferralTree Component
const UserReferralTree = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { auth } = useSelector((state) => state.auth);
  const { treeData } = useSelector((state) => state.referralTree);


  useEffect(() => {
    if (auth?.refferal_code) {
      dispatch(getTreeData(auth?.refferal_code))
    }
  }, [auth?.refferal_code]);
  return (
    <>
      <div className="flex justify-center">
        <div className="relative">
          <img
            src="/default.jpg" // Placeholder image
            alt={auth?.username}
            className="w-10 h-10 rounded-full border border-gray-300"
          />
          <div className="absolute w-0.5 h-full bg-gray-300 left-1/2 transform -translate-x-1/2 top-10"></div>
        </div>
        <div className="ml-2">
          <div className="font-semibold">{auth?.username}</div>
          <div>ID: {auth?.id}</div>
          <div>Email: {auth?.email}</div>
          <div>Status: {auth?.is_active}</div>
        </div>
      </div>

      <div className="p-6">
        {error ? (
          <p>{error}</p>
        ) : treeData?.length > 0 ? (
          <div className="flex w-full justify-space-around items-center">
            {treeData?.map((user) => (
              <div key={user.id} className="mx-auto mb-6">
                <TreeNode user={user} />
              </div>
            ))}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </>
  );
};

export default UserReferralTree;
