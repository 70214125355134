import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Loader from "../BaseFile/comman/Loader";
import { AiFillDelete } from "react-icons/ai";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import { Confirmation } from "../BaseFile/comman/Confirmation";
import Spinner from "../BaseFile/comman/Spinner";
import {
  addTopup,
  getAllTopupByid,
  deleteTopup,
  clearErrors,
  clearMessage,
} from "../redux/topupSlice";
import { getUserbyemail } from "../redux/userSlice";
import { getAllPlans } from "../redux/planSlice";

export default function UserTopup() {
  const dispatch = useDispatch();
  const { singletopup, loading, error, message } = useSelector(
    (state) => state.alltopup
  );
  const { allplans } = useSelector((state) => state.allplans);
  const { emailuser } = useSelector((state) => state.allusers);
  const { auth } = useSelector((state) => state.auth);
  const [values, setValues] = useState({});
  const [deleteID, setDeleteID] = useState();
  const [userby, setUserby] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    dispatch(getAllPlans());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message]);

  useEffect(() => {
    if (auth?.id) {
      dispatch(getAllTopupByid(auth?.id));
    }
    if (userby && userby !== auth?.email) {
      dispatch(getUserbyemail(userby));
    }
  }, [auth?.id, dispatch, userby]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    const form = e.target.closest("form");
    if (form.checkValidity()) {
      const allValues = {
        ...values,
        userby_id: auth?.id,
        userto_id: emailuser?.id,
      };
      console.log(allValues);
      dispatch(addTopup({ values: allValues }));
    } else {
      form.reportValidity();
    }
  };
  const handleDelete = (id) => {
    setDeleteID(id);
    setModalOpen(true);
  };

  const isClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="bg-gray-100">
        <div className="w-full mx-auto bg-white rounded shadow overflow-hidden">
          {message && <SuccessAlert message={message} />}
          {error && <ErrorAlert error={error} />}
          <div className="px-6 py-4">
            <h2 className="text-xl font-semibold mb-5 text-gray-800 mb-2">
              Topup Request
            </h2>
            <form className="flex justify-between">
              <div className="flex w-full justify-gap-around gap-8">
                <div className="mb-4 w-1/4">
                  <label className="block text-sm font-medium text-gray-700">
                    User
                  </label>
                  <input
                    type="text"
                    name="email"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="type user E-Mail . . ."
                    onChange={(e) => setUserby(e.target.value)}
                  />
                  <p
                    className={
                      emailuser?.username ? "text-green-500" : "text-red-500"
                    }
                  >
                    {emailuser?.username
                      ? emailuser.username
                      : "Provide user email"}
                  </p>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Plan
                  </label>

                  {emailuser?.is_active === "active" ? (
                    <input
                      type="number"
                      name="investment_amount"
                      min="200"
                      step="100"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder="Enter investment amount (multiple of 100, minimum 200)"
                      onChange={handleChange}
                      required
                    />
                  ) : (
                    <select
                      name="id"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select a plan</option>
                      {allplans?.map((plan, index) => (
                        <option key={index} value={plan.id}>
                          {plan.name} - ${plan.monthly_price} Activation Plan
                        </option>
                      ))}
                    </select>
                  )}
                </div>

                <div className=" flex justify-center items-center pb-4">
                  <button
                    type="submit"
                    disabled={!emailuser} // Disable if emailuser is falsy
                    onClick={handleSaveChanges}
                    className={`px-4 py-2 rounded text-white focus:outline-none ${
                      emailuser
                        ? "bg-indigo-500 hover:bg-indigo-600 focus:bg-indigo-600" // Styles for the enabled state
                        : "bg-gray-400 cursor-not-allowed" // Styles for the disabled state
                    }`}
                  >
                    {loading ? <Spinner /> : "TopUp"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className={`${loading ? "h-[260px] items-center" : "h-full"}`}>
          {loading ? (
            <Loader />
          ) : (
            <div className="bg-gray-900 ">
              <div className=" flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <h1 className="text-white text-2xl font-semibold m-8">
                      Topup History
                    </h1>
                    <table className="z-10 mt-6 w-full whitespace-nowrap text-left">
                      <thead className="border-b border-white/10 text-sm leading-6 text-white">
                        <tr>
                          <th
                            scope="col"
                            className="py-2 pl-4 font-semibold sm:pl-6 lg:pl-8"
                          >
                            Topup To
                          </th>
                          <th
                            scope="col"
                            className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell"
                          >
                            amount
                          </th>
                          <th
                            scope="col"
                            className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                          >
                            Status
                          </th>

                          <th
                            scope="col"
                            className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                          >
                            Request at
                          </th>
                          <th
                            scope="col"
                            className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-white/5">
                        {singletopup
                          ?.slice()
                          .reverse()
                          .map((item, index) => (
                            <tr key={index}>
                              <td className="py-4 pl-4 sm:pl-6 lg:pl-8">
                                <div className="flex items-center">
                                  <div className="truncate text-sm font-medium leading-6 text-white">
                                    {item?.userto_id}
                                  </div>
                                </div>
                              </td>
                              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                                <div className="">
                                  <div className="font-mono text-sm leading-6 text-gray-400">
                                    ${item?.amount}
                                  </div>
                                </div>
                              </td>
                              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                                <div className="">
                                  <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                                    {item?.status}
                                  </div>
                                </div>
                              </td>

                              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                                <div className="">
                                  <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                                    {item?.createdAT}
                                  </div>
                                </div>
                              </td>

                              <td className="py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                                <div className="z-0 flex space-x-4">
                                  <>
                                    <AiFillDelete
                                      className="h-4 w-4 text-red-400 cursor-pointer"
                                      onClick={() => handleDelete(item?.id)}
                                      title="Delete"
                                    />
                                  </>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {modalOpen && (
          <Confirmation
            isClose={isClose}
            deletefunction={deleteTopup}
            id={deleteID}
          />
        )}
      </div>
    </>
  );
}
