import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrView } from "react-icons/gr";
import { useParams } from "react-router-dom";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import { Confirmation } from "../BaseFile/comman/Confirmation";
import Loader from "../BaseFile/comman/Loader";
import { TbBinaryTree } from "react-icons/tb";
import {
  getAllUsers,
  clearErrors,
  deleteUsers,
  clearMessage,
} from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";

export default function AdminIncome() {
  const { action } = useParams();
  const dispatch = useDispatch();
  const { allusers, loading, error, message } = useSelector(
    (state) => state.allusers
  );
  const [allUser, setAllUser] = useState(allusers);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalopen, setModalopen] = useState(false);
  const [deleteID, setdeleteID] = useState();
  const IconContainer = ({ children }) => (
    <div className="z-0 relative flex items-center justify-center">
        {children}
    </div>
  );

  useEffect(() => {
    dispatch(getAllUsers());
    setAllUser(allusers);

    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch,error,message,clearErrors,clearMessage]);


  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setAllUser(
      allusers?.filter((p) => p.username?.toLowerCase().includes(searchTerm))
    );
    setSearchQuery(e.target.value);
  };

  function isClose() {
    setModalopen(false);
  }
  function handleDelete(id) {
    setdeleteID(id);
    if (deleteID) {
      console.log(id);
      setModalopen(true);
    }
  }
  console.log(allusers)

  return (
    <div className="bg-gray-900 ">
      <div className="px-8 pt-2">
        <label htmlFor="email" className="sr-only">
          Search
        </label>
        <input
          id="search"
          name="search"
          value={searchQuery}
          onChange={(e) => handleSearch(e)}
          type="text"
          placeholder="search here . . ."
          className="block w-[50vh] rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}

      <div
        className={`flex justify-center ${
          loading ? "h-[560px] items-center" : "h-full"
        }`}
      >
        {loading ? (
          <Loader />
        ) : (
          <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-700">

          <thead className="border-b border-white/10 text-sm leading-6 text-white">
            <tr>
              <th scope="col" className=" pr-8 font-semibold">
                Name
              </th>
              <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
              Active / Investment
              </th>
              <th scope="col" className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8">
              Direct
              </th>
              <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
              Level Day / Total 
              </th>
              <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
              Roi Day / Total
              </th>
              <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
              Investment Day / Total
              </th>
              <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
              Reward
              </th>
              <th scope="col" className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                Tree
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-white/5 text-center">
            {(searchQuery ? allUser : allusers )?.map((item, index) => (
              <tr key={index}>
                <td className=" w-1/6">
                  <div className="flex items-center gap-x-4">
                    <div className="truncate text-sm font-medium leading-6 text-white w-full">
                        {item?.email}
                    </div>
                  </div>
                </td>
                <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8 w-1/6">
                  <div className="flex gap-x-3">
                    <div className="font-mono text-sm leading-6 text-gray-400 w-full">
                        {item?.active_plan} / {item?.investment_plan}
                    </div>
                  </div>
                </td>
               
                <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20 w-1/6">
                    {item?.direct_income}
                </td>
                <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20 w-1/6">
                   {item?.level_income_status} / {item?.level_month}
                </td>
                <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20 w-1/6">
                   {item?.roi_day} / {item?.roi_income}
                </td>
                <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20 w-1/6">
                  {item?.invesmetn_level_income} / {item?.investment_month}
                </td>
                <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20 w-1/6">
                  {item?.reward}
                </td>
                  <td className="px-2 py-4 flex gap-3 items-center ">
                  <Link to={`/admin/refferal/${item?.refferal_code}`}>
                        <IconContainer>
                          <TbBinaryTree
                            className="h-4 w-4 text-white cursor-pointer"
                            title="details"
                          />
                        </IconContainer>
                      </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          </div>

          </div>

        )}
      </div>
      {modalopen && (
        <Confirmation
          isClose={isClose}
          deletefunction={deleteUsers}
          id={deleteID}
        />
      )}
    </div>
  );
}
