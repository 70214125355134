import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrView } from "react-icons/gr";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import { Confirmation } from "../BaseFile/comman/Confirmation";
import Loader from "../BaseFile/comman/Loader";
import { TbBinaryTree } from "react-icons/tb";
import { getAllDeposite } from "../redux/depositeSlice";
import { getAllUsers } from "../redux/userSlice";
import { getAllWithdrawal } from "../redux/withdrawalSlice";
import { useDispatch, useSelector } from "react-redux";

export default function AdminReport() {
  const dispatch = useDispatch();
  const { allusers } = useSelector((state) => state.allusers);
  const { allwithdrawal } = useSelector((state) => state.allwithdrawal);
  const { alldeposite, loading, error, message } = useSelector(
    (state) => state.alldeposite
  );

  const [allUser, setAllUser] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const IconContainer = ({ children }) => (
    <div className="z-0 relative flex items-center justify-center">
      {children}
    </div>
  );

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllWithdrawal());
    dispatch(getAllDeposite());
  }, [dispatch]);

  function findLastEntryByCreatedAt(arr) {
    return arr?.reduce(
      (acc, current) => (acc.created_at > current.created_at ? acc : current),
      {}
    );
  }
  let combinedArray = allusers?.map((user) => {
    let lastWithdrawal = findLastEntryByCreatedAt(
      allwithdrawal?.filter((w) => w.user_id === user.id)
    );
    let lastDeposit = findLastEntryByCreatedAt(
      alldeposite?.filter((d) => d.user_id === user.id)
    );
    return {
      id: user?.id,
      name: user?.username,
      last_login: user?.last_login,
      created_at: user?.created_at,
      updated_at: user?.updated_at,
      active_plan: user?.active_plan,
      activation_date: user?.activation_date,
      last_withdrawal_created_at: lastWithdrawal?.createdAT || null,
      last_withdrawal_amount: lastWithdrawal?.amount || null,
      last_deposit_created_at: lastDeposit?.createdAT || null,
      last_deposit_amount: lastDeposit?.amount || null,
    };
  });

  useEffect(() => {
    setAllUser(combinedArray);
    if (searchQuery) {
      setAllUser(
        combinedArray?.filter((p) =>
          p.name?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [searchQuery]);
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="bg-gray-900 ">
      <div className="px-8 pt-2">
        <label htmlFor="email" className="sr-only">
          Search
        </label>
        <input
          id="search"
          name="search"
          value={searchQuery}
          onChange={(e) => handleSearch(e)}
          type="text"
          placeholder="search here . . ."
          className="block w-[50vh] rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}

      <div
        className={`flex justify-center ${
          loading ? "h-[560px] items-center" : "h-full"
        }`}
      >
        {loading ? (
          <Loader />
        ) : (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead className="border-b border-white/10 text-sm leading-6 text-white">
                    <tr>
                      <th
                        scope="col"
                        className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8"
                      >
                        Id
                      </th>
                      <th
                        scope="col"
                        className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell"
                      >
                        Last Deposite
                      </th>
                      <th
                        scope="col"
                        className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                      >
                        Last Withdrawal
                      </th>
                      <th
                        scope="col"
                        className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                      >
                        Activation Date
                      </th>
                      <th
                        scope="col"
                        className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20"
                      >
                        Last Update
                      </th>
                      <th
                        scope="col"
                        className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                      >
                        Last Login
                      </th>
                      <th
                        scope="col"
                        className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                      >
                        Created AT
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-white/5">
                    {(searchQuery ? allUser : combinedArray)?.map((item, index) => (
                      <tr key={index}>
                        <td className="py-4 pl-4 sm:pl-6 lg:pl-8">
                          <div className="flex items-center">
                            <div className="truncate text-sm font-medium leading-6 text-white">
                              {item?.id}
                            </div>
                          </div>
                        </td>
                        <td className="py-4 pl-4 sm:pl-6 lg:pl-8">
                          <div className="flex items-center">
                            <div className="truncate text-sm font-medium leading-6 text-white">
                              {item?.name}
                            </div>
                          </div>
                        </td>
                        <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                          <div className="">
                            <div className="font-mono text-sm leading-6 text-gray-400">
                              {item?.last_deposit_created_at}
                            </div>
                            <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                              {item?.last_deposit_amount}
                            </div>
                          </div>
                        </td>
                        <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                          <div className="">
                            <div className="font-mono text-sm leading-6 text-gray-400">
                              {item?.last_withdrawal_created_at}
                            </div>
                            <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                              {item?.last_withdrawal_amount}
                            </div>
                          </div>
                        </td>
                        <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20">
                          {item?.activation_date}
                        </td>

                        <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20">
                          {item?.updated_at}
                        </td>
                        <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20">
                          {item?.last_login}
                        </td>
                        <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20">
                          {item?.created_at}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
