import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../BaseFile/comman/Spinner";
import { QRCode } from "react-qrcode";
import{addQrLink,getQrLink,deleteQrLink , clearErrors,clearMessage} from "../redux/qrSlice"
import { useQRCode } from "react-qrcode";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import { AiFillDelete } from "react-icons/ai";
import Loader from "../BaseFile/comman/Loader";
import { Confirmation } from "../BaseFile/comman/Confirmation";
const AdminQrLink = () => {
  const dispatch = useDispatch();
  const { qr,loading , message,error } = useSelector((state) => state.qr);
  const [qrLink, setQrLink] = useState("");
  const [deleteID, setDeleteID] = useState();
  const [modalOpen, setModalOpen] = useState(false);


  const handleSubmit = (event) => {
    event.preventDefault(); 
    dispatch(addQrLink(qrLink));
  };
  const handleLinkChange = (event) => {
    setQrLink(event.target.value);
  };

  useEffect(() => {
    dispatch(getQrLink());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message]);

  const handleDelete = (id) => {
    setDeleteID(id);
    setModalOpen(true);
  };
  const isClose = () => {
    setModalOpen(false);
  };
  return (
    <>
      <div className="">
        <form onSubmit={handleSubmit}>
          <div className="flex w-full justify-gap-around gap-8">
            <div className="mb-4 w-1/2">
            {message && <SuccessAlert message={message} />}
            {error && <ErrorAlert error={error} />}
              <label className="block text-sm font-medium text-gray-700">
                link
              </label>
              <input
                type="text"
                name="link"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="type user link . . ."
                value={qrLink}
                onChange={handleLinkChange}
              />
            </div>
          
            <div className=" flex justify-center items-center pb-4">
              <button
                type="submit"
                className="px-4 py-2 mt-7  bg-indigo-500 text-white rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
              >
                {loading ? <Spinner /> : "Set Link"}
              </button>
            </div>
          </div>
        </form>

        <div className={`${loading ? "h-[260px] items-center" : "h-full"}`}>
          {loading ? (
            <Loader />
          ) : (
    <div className="bg-gray-900 ">

            <div className=" flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <h1 className="text-white text-2xl font-semibold m-8">QrLink History</h1>
                  <table className="z-10 mt-6 w-full whitespace-nowrap text-left">
                    <thead className="border-b border-white/10 text-sm leading-6 text-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-2 pl-4 font-semibold sm:pl-6 lg:pl-8"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell"
                        >
                          Link
                        </th>
                     
                        <th
                          scope="col"
                          className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                        >
                          Set at
                        </th>
                        <th
                          scope="col"
                          className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-white/5">
                    {qr?.map((item, index) => (
                        <tr key={index}>
                          <td className="py-4 pl-4 sm:pl-6 lg:pl-8">
                            <div className="flex items-center">
                              <div className="truncate text-sm font-medium leading-6 text-white">
                                {item?.id}
                              </div>
                            </div>
                          </td>
                          <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                            <div className="">
                              <div className="font-mono text-sm leading-6 text-gray-400">
                                ${item?.link}
                              </div>
                            </div>
                          </td>
                      
                          <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                            <div className="">
                              <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                                {item?.createdAT}
                              </div>
                            </div>
                          </td>

                          <td className="py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                            <div className="z-0 flex space-x-4">
                              <>
                                <AiFillDelete
                                  className="h-4 w-4 text-red-400 cursor-pointer"
                                  onClick={() => handleDelete(item?.id)}
                                  title="Delete"
                                />
                              </>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            </div>
          )}
        </div>
        {modalOpen && (
          <Confirmation
            isClose={isClose}
            deletefunction={deleteQrLink}
            id={deleteID}
          />
        )}
      </div>
    </>
  );
};

export default AdminQrLink;
