import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../BaseFile/comman/Spinner";
import Loader from "../BaseFile/comman/Loader";
import { Confirmation } from "../BaseFile/comman/Confirmation";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import { addRecharge } from "../redux/topupSlice";
import { useQRCode } from "react-qrcode";
import { getQrLink } from "../redux/qrSlice";
import { AiFillDelete } from "react-icons/ai";

import {
  addDeposite,
  getAllDepositeByid,
  deleteDeposite,
  clearErrors,
  clearMessage,
} from "../redux/depositeSlice";

const UserDepostie = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { qr } = useSelector((state) => state.qr);
  const { singleDeposite, loading, error, message } = useSelector(
    (state) => state.alldeposite
  );
  const [file, setFile] = useState(null);
  const [fieldError, setFiledError] = useState(null);
  const [amount, setAmount] = useState("");
  const [value, setValue] = useState("");
  const [deleteID, setDeleteID] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [currency, setCurrency] = useState("crypto");
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    dispatch(getQrLink());
    dispatch(getAllDepositeByid(auth?.id));
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message, auth?.id]);

  useEffect(() => {
    if (qr && qr.length > 0) {
      const latestQr = qr.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )[0];
      setValue(latestQr.link);
    }
  }, [qr]);

  const dataUrl = useQRCode(value);

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const formData = new FormData();
    formData.append("image", file);
    formData.append("amount", amount);
    formData.append("user_id", auth?.id);
    formData.append("currency", currency);

    dispatch(addDeposite(formData));
  };
  const handleDelete = (id) => {
    setDeleteID(id);
    setModalOpen(true);
  };
  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };
  const isClose = () => {
    setModalOpen(false);
  };
  const handleImageClick = (imageName) => {
    setPreviewImage(`../../api.winxto.top/uploads/${imageName}`);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  return (
    <>
      <div className="flex justify-between space-y-4">
        {message && <SuccessAlert message={message} />}
        {error && <ErrorAlert error={error} />}
        <div className="w-2/3">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex justify-center items-center space-x-4 w-3/4">
            <div className="w-full mb-4">
              <label
                htmlFor="amount"
                className="block text-sm font-medium text-gray-700"
              >
                Amount
              </label>
              <input
                id="amount"
                type="number"
                name="amount"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Type user amount..."
                value={amount}
                onChange={handleAmountChange}
              />
            </div>
            <div className="">
              <div className="relative">
                <input
                  id="file"
                  type="file"
                  name="image"
                  onChange={handleFileChange}
                  className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
                />
                <button
                  type="button"
                  className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  onClick={() => document.getElementById("file").click()}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
          <div className="flex w-full space-x-4">
            <div className="w-3/4 mb-4">
              <label
                htmlFor="currency"
                className="block text-sm font-medium text-gray-700"
              >
                Currency
              </label>{" "}
              
              <div className="flex gap-5">
                <select
                  id="currency"
                  name="currency"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={currency}
                  onChange={handleCurrencyChange}
                >
                  <option value="crypto">Crypto</option>
                </select>
                <button
                  type="submit"
                  className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
                >
                  {loading ? <Spinner /> : "Recharge"}
                </button>
              </div>
            </div>
          </div>
          {fieldError && <div className="text-red-500">{fieldError}</div>}
        </form>
        </div>
        <div className=" w-1/3">
          {dataUrl && (
            <img
              className="w-48 h-48 object-cover"
              src={dataUrl}
              alt="QR Code"
            />
          )}
        </div>
      </div>
      <div className={`${loading ? "h-[260px] items-center" : "h-full"}`}>
        {loading ? (
          <Loader />
        ) : (
          <div className="bg-gray-900">
            <div className="flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <h1 className="text-white text-2xl font-semibold m-8">
                    Deposite History
                  </h1>
                  <table className="z-10 mt-6 w-full whitespace-nowrap text-left">
                    <thead className="border-b border-white/10 text-sm leading-6 text-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-2 pl-4 font-semibold sm:pl-6 lg:pl-8"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell"
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                        >
                          Recipt
                        </th>
                        <th
                          scope="col"
                          className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                        >
                         Request/Accept
                        </th>
                        <th
                          scope="col"
                          className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-white/5">
                      {singleDeposite
                        ?.slice()
                        .reverse()
                        .map((item, index) => (
                          <tr key={index}>
                            <td className="py-4 pl-4 sm:pl-6 lg:pl-8">
                              <div className="flex items-center">
                                <div className="truncate text-sm font-medium leading-6 text-white">
                                  {item?.id}
                                </div>
                              </div>
                            </td>
                            <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                              <div className="">
                                <div className="font-mono text-sm leading-6 text-gray-400">
                                  ${item?.amount}
                                </div>
                              </div>
                            </td>
                            <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                              <div className="">
                                <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                                  {item?.status}
                                </div>
                              </div>
                            </td>
                            <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-6 lg:pr-8">
                                <div className="flex items-center space-x-2">
                                  {item?.image_name && (
                                    <button
                                      onClick={() =>
                                        handleImageClick(item?.image_name)
                                      }
                                      className="text-blue-400 hover:underline"
                                    >
                                      {item?.image_name?.slice(0, 10)}
                                    </button>
                                  )}
                                </div>
                              </td>
                            <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                              <div className="">
                                <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                                {item?.createdAT} / {item?.acceptat}
                                </div>
                              </div>
                            </td>
                            <td className="py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                              <div className="z-0 flex space-x-4">
                                <>
                                  <AiFillDelete
                                    className="h-4 w-4 text-red-400 cursor-pointer"
                                    onClick={() => handleDelete(item?.id)}
                                    title="Delete"
                                  />
                                </>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {previewImage && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70">
            <div className="relative">
              <img
                src={previewImage}
                alt="Preview"
                className="max-w-full max-h-screen object-contain"
              />
              <button
                onClick={handleClosePreview}
                className="absolute top-2 right-2 text-white text-xl bg-black p-2 rounded-full"
              >
                ×
              </button>
            </div>
          </div>
        )}
      {modalOpen && (
        <Confirmation
          isClose={isClose}
          deletefunction={deleteDeposite}
          id={deleteID}
        />
      )}
    </>
  );
};

export default UserDepostie;
