import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useState ,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { signoutuser } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getUser } from "../../redux/userSlice";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const network=[
  {name:"Direrct Member" , to:"/user/directmember",current:false},
  {name:"Referral Tree" , to:"/user/referraltree",current:false},
]
const wallet=[
  {name:"Deposite" , to:"/user/adddeposite",current:false},
  {name:"Withdrawal" , to:"/user/addwithdrawal",current:false},
  {name:"TopUp" , to:"/user/topup",current:false},
  {name:"ReTopUp" , to:"/user/retopup",current:false},
]
const income=[
  {name:"Detail" , to:"/user/income",current:false},
  // {name:"Direct" , to:"/user/transaction/direct_transaction",current:false},
  {name:"Level" , to:"/user/transaction/active_level_transaction",current:false},
  {name:"invest" , to:"/user/transaction/invest_level_transaction",current:false},
  {name:"ROI" , to:"/user/transaction/roi_transaction",current:false},
]
const navigation = [
  { name: "Dashboard", to: "/user/dashboard", current: true , submenu:[]},
  { name: "Network", to: "/user/directmember", current: false ,submenu:network},
  { name: "Wallet", to: "/user/adddeposite", current: false ,submenu:wallet},
  { name: "Income", to: "/user/income", current: false ,submenu:income},
  { name: "Membership Plan", to: "/user/plan", current: false, submenu:[] },
  { name: "Support", to: "/user/sendsupport", current: false , submenu:[]},
];


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UserMenu({ Children, PageName }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const {singleuser } = useSelector(
    (state) => state.allusers
  );
  useEffect(() => {
    dispatch(getUser(auth?.id))

  },[auth?.id]);
  const [childMenu , setChildMenu] =useState();
  function handleLogout() {
    dispatch(signoutuser());
    navigate("/login");
  }
  return (
    <>
      <div className="min-h-full">
        <Disclosure
          as="nav"
          className="border-b border-gray-700 border-opacity-25 bg-gray-800 lg:border-none"
        >
          <div className="mx-8 max-w-full px-2 sm:px-4 lg:px-8">
            <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-gray-400 lg:border-opacity-25">
              <div className="flex items-center px-2 lg:px-0">
                <div className="flex-shrink-0">
                  <img
                    alt="Your Company"
                    src="https://tailwindui.com/img/logos/mark.svg?color=gray&shade=300"
                    className="block h-8 w-8"
                  />
                </div>
                <div className="hidden lg:ml-10 lg:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        onClick={()=>setChildMenu(item.submenu)}
                        aria-current={item.current ? "page" : undefined}
                        className={classNames(
                          item.current
                            ? "bg-gray-700 text-white"
                            : "text-white hover:bg-gray-500 hover:bg-opacity-75",
                          "rounded-md px-3 py-2 text-sm font-medium"
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
                <div className="w-full max-w-lg lg:max-w-xs">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative text-gray-400 focus-within:text-gray-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon
                        aria-hidden="true"
                        className="h-5 w-5"
                      />
                    </div>
                    <input
                      id="search"
                      name="search"
                      type="search"
                      placeholder="Search"
                      className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <div className="flex lg:hidden">
                {/* Mobile menu button */}
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-gray-600 p-2 text-gray-200 hover:bg-gray-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-600">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon
                    aria-hidden="true"
                    className="block h-6 w-6 group-data-[open]:hidden"
                  />
                  <XMarkIcon
                    aria-hidden="true"
                    className="hidden h-6 w-6 group-data-[open]:block"
                  />
                </DisclosureButton>
              </div>
              <div className="hidden lg:ml-4 lg:block">
                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    className="relative flex-shrink-0 rounded-full bg-gray-600 p-1 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-600"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="p-4">Active Wallet : ${singleuser?.business}</span>
                  </button>
                  <button
                    type="button"
                    className="relative flex-shrink-0 rounded-full bg-gray-600 p-1 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-600"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3 flex-shrink-0">
                    <div>
                      <MenuButton className="relative flex rounded-full bg-gray-600 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-600">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <img
                          alt=""
                          src={user.imageUrl}
                          className="h-8 w-8 rounded-full"
                        />
                      </MenuButton>
                    </div>
                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                       <MenuItem >
                          <Link
                            to={`/user/profile/${auth?.id}`}
                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                          >
                            {auth?.username}
                          </Link>
                        </MenuItem>
                         <MenuItem >
                          <Link
                            onClick={handleLogout}
                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                          >
                            Logout
                          </Link>
                        </MenuItem>
                    </MenuItems>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <DisclosurePanel className="lg:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <DisclosureButton
                  key={item.name}
                  as="a"
                  to={item.to}
                  onClick={()=>setChildMenu(item.submenu)}
                  aria-current={item.current ? "page" : undefined}
                  className={classNames(
                    item.current
                      ? "bg-gray-700 text-white"
                      : "text-white hover:bg-gray-500 hover:bg-opacity-75",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                >
                  {item.name}
                </DisclosureButton>
              ))}
            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="flex items-center px-5">
                
                <button
                  type="button"
                  className="relative ml-auto flex-shrink-0 rounded-full bg-gray-600 p-1 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-600"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
              <div className="mt-3 space-y-1 px-2">
                  <DisclosureButton
                    to={`/user/profile/${auth?.id}`}
                    className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-gray-500 hover:bg-opacity-75"
                  >
                    {auth?.usernmae}
                  </DisclosureButton>
                  <DisclosureButton
                   onClick={handleLogout}
                    className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-gray-500 hover:bg-opacity-75"
                  >
                    Logout
                  </DisclosureButton>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>

        <header className="py-6 bg-gray-800 text-white pb-56">
          <div className="flex justify-between mx-8">
            <div className="px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">
                {" "}
                {PageName}
              </h1>
            </div>
            <Disclosure as="header" className="bg-gray-800">
              <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                <div className="relative flex h-16 justify-between lg:hidden">
                  <div className="relative z-10 flex items-center lg:hidden">
                    {/* Mobile menu button */}
                    <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open menu</span>
                      <Bars3Icon
                        aria-hidden="true"
                        className="block h-6 w-6 group-data-[open]:hidden"
                      />
                      <XMarkIcon
                        aria-hidden="true"
                        className="hidden h-6 w-6 group-data-[open]:block"
                      />
                    </DisclosureButton>
                  </div>
                </div>
                <nav
                  aria-label="Global"
                  className="hidden lg:flex lg:space-x-8 lg:py-2"
                >
                  {childMenu?.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}

                      aria-current={item.current ? "page" : undefined}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "inline-flex items-center rounded-md px-3 py-2 text-sm font-medium"
                      )}
                    >
                      {item.name}
                    </Link>
                  ))}
                </nav>
              </div>

              <DisclosurePanel
                as="nav"
                aria-label="Global"
                className="lg:hidden"
              >
                <div className="space-y-1 px-2 pb-3 pt-2">
                  {childMenu?.map((item) => (
                    <DisclosureButton
                      key={item.name}
                      as="a"
                      to={item.to}
                      aria-current={item.current ? "page" : undefined}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                    >
                      {item.name}
                    </DisclosureButton>
                  ))}
                </div>
              </DisclosurePanel>
            </Disclosure>
          </div>
        </header>

        <main className="-mt-48">
          <div className="mx-8 max-w-8xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-white px-5 py-6 shadow-2xl sm:px-6">
              {Children}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
