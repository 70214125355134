import { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from "@headlessui/react";
import { getAllUsers,getUser } from "../redux/userSlice";
import {getAllDeposite } from "../redux/depositeSlice";
import {
  Bars3CenterLeftIcon,
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  BanknotesIcon,
  BuildingOfficeIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AdminSetting from "./AdminSetting";
import {getAllWithdrawal,} from "../redux/withdrawalSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function AdminDashboard() {
  const { auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { allusers, loading, error, message } = useSelector(
    (state) => state.allusers
  );
  const { alldeposite } = useSelector(
    (state) => state.alldeposite
  );
  const { allwithdrawal} = useSelector(
    (state) => state.allwithdrawal
  );
  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllDeposite());
    dispatch(getAllWithdrawal())
  },[auth?.id]);
  let totalCount = allusers?.length;
  let activeCount = allusers?.filter(
    (user) => user?.is_active == "active"
  ).length;
  let blockCount = allusers?.filter((user) => user?.status == "block").length;
  let activePlanSum = allusers
    ?.filter((user) => user.is_active)
    .reduce((sum, user) => sum + user.active_plan, 0);
    let Investmentsum = allusers
    ?.reduce((sum, user) => sum + user.investment_plan, 0);
  let inactiveCount = totalCount - activeCount;
  let unblockCount = totalCount - blockCount;


  const today = new Date().toISOString().slice(0, 10);

// Filter users who joined today
let joinedTodayCount = allusers?.filter(user => {
    const createdAtDate = user?.created_at?.slice(0, 10); // Extract YYYY-MM-DD from created_at
    return createdAtDate === today;
}).length;

let pendingDepositsCount = alldeposite?.filter(deposit => deposit.status === "pending").length;
let pendingaWithdrawalCount = allwithdrawal?.filter(wd => wd.status === "pending").length;
  const cards = [
    {
      name: "Total User",
      to: "/admin/user/all",
      icon: ScaleIcon,
      amount: totalCount,
    },
    {
      name: "Active Member",
      to: "/admin/user/active",
      icon: ScaleIcon,
      amount: activeCount,
    },
    {
      name: "Inactive Memebr",
      to: "/admin/user/inactive",
      icon: ScaleIcon,
      amount: inactiveCount,
    },
    {
      name: "Subscription Plan Amount",
      to: "/admin/dashboard",
      icon: ScaleIcon,
      amount: ` $${activePlanSum} `,
    },
    {
      name: "Block Member",
      to: "/admin/user/block",
      icon: ScaleIcon,
      amount: blockCount,
    },
    {
      name: "Unblock Member",
      to: "/admin/user/unblock",
      icon: ScaleIcon,
      amount: unblockCount,
    },
  ];
  const cards2 = [
    { name: "Total Investment", to: "#", icon: ScaleIcon, amount:'$'+ Investmentsum},
    { name: "Today Join", to: "#", icon: ScaleIcon, amount: joinedTodayCount },
    { name: "Pending Deposite", to: "#", icon: ScaleIcon, amount: pendingDepositsCount },
    {
      name: "Pending Withdrawal",
      to: "#",
      icon: ScaleIcon,
      amount: pendingaWithdrawalCount,
    },
  ];
  return (
    <>
      <div className="min-h-full ">
        <div className="flex flex-1 flex-col">
          <main className="flex-1 pb-8 ">
            {/* Page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                  <div className="min-w-0 flex-1">
                    {/* Profile */}
                    <div className="flex items-center">
                      <img
                        alt=""
                        src="/default.jpg"
                        className="hidden h-16 w-16 rounded-full sm:block"
                      />
                      <div>
                        <div className="flex items-center">
                          <img
                            alt=""
                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                            className="h-16 w-16 rounded-full sm:hidden"
                          />
                          <h1 className="flex ml-3 text-2xl capitalize font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                            Welocome, {auth?.username}
                            <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                              <CheckCircleIcon
                                aria-hidden="true"
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                              />
                            </dd>
                          </h1>
                        </div>
                        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                          <dt className="sr-only">Company</dt>
                          <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                            You have done 72% 🤩 more sales today. Check your
                            new raising badge in your profile.
                          </dd>
                          <dt className="sr-only">Account status</dt>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:ml-4 md:mt-0">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                    >
                      Downlaod
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  Overview
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  {/* Card */}
                  {cards.map((card) => (
                    <div
                      key={card.name}
                      className="overflow-hidden rounded-lg bg-white shadow-2xl"
                    >
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <card.icon
                              aria-hidden="true"
                              className="h-6 w-6 text-gray-400"
                            />
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="truncate text-sm font-medium text-gray-500">
                                {card.name}
                              </dt>
                              <dd>
                                <div className="text-lg font-medium text-gray-900">
                                  {card.amount}
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-5 py-3">
                        <div className="text-sm">
                          <Link
                            to={card.to}
                            className="font-medium text-cyan-700 hover:text-cyan-900"
                          >
                            View all
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-8 w-full h-[2px] bg-gray-700 "></div>

              <div className="py-8  px-8">
                    <AdminSetting />
                </div>
              <div className="mt-8 w-full h-[2px] bg-gray-700 "></div>

              {/* Activity table (small breakpoint and up) */}
              <div className="mt-8 mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="mx-auto mt-8 max-w-6xl text-lg font-medium leading-6 text-gray-900">
                  Other Information
                </h2>
                <div>
                  {" "}
                 
                  <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2 ">
                    {/* Card */}
                    {cards2.map((card) => (
                      <div
                        key={card.name}
                        className="overflow-hidden rounded-lg bg-white shadow-2xl"
                      >
                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <card.icon
                                aria-hidden="true"
                                className="h-6 w-6 text-gray-400"
                              />
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                                <dt className="truncate text-sm font-medium text-gray-500">
                                  {card.name}
                                </dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">
                                    {card.amount}
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-5 py-3">
                          <div className="text-sm">
                            <Link
                              to={card.to}
                              className="font-medium text-cyan-700 hover:text-cyan-900"
                            >
                              View all
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

            </div>
          </main>
        </div>
      </div>
    </>
  );
}
