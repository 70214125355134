import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FaCheck, FaTimes } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import { GrEdit } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import Loader from "../BaseFile/comman/Loader";
import {
  getAllDeposite,
  deleteDeposite,
  clearErrors,
  clearMessage,
  updateDeposite,
} from "../redux/depositeSlice"
import { Confirmation } from "../BaseFile/comman/Confirmation";
import { getAllUsers } from "../redux/userSlice";

export default function AdminDeposite() {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [editableDeposite, setEditableDeposite] = useState(null);
  const [allDeposite, setAllDeposite] = useState([]);
  const [deleteID, setDeleteID] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [values, setValues] = useState();
  const [previewImage, setPreviewImage] = useState(null);

  const { alldeposite, loading, error, message } = useSelector(
    (state) => state.alldeposite
  );
  const { allusers } = useSelector(
    (state) => state.allusers
  );
  useEffect(() => {
    dispatch(getAllDeposite());
    setAllDeposite(alldeposite);
    dispatch(getAllUsers());

    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message]);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setAllDeposite(
      alldeposite?.filter((p) =>toString(p?.amount).includes(e.target.value))
    );
    setSearchQuery(e.target.value);
  };

  const handleDelete = (id) => {
    setDeleteID(id);
    setModalOpen(true);
  };

  const isClose = () => {
    setModalOpen(false);
  };

  const handleEdit = (item) => {
    setEditableDeposite(item);
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditableDeposite(null);
  };

  const handleChange = (e) => {
    setValues(e.target.value)};


  const handleSaveChange = (id) => {
    if (editableDeposite) {
      dispatch(updateDeposite({ id:id, status:values , amount:editableDeposite?.amount , user_id:editableDeposite?.user_id}));
      setEditMode(false);
      setEditableDeposite(null);
    }
  };
  const handleImageClick = (imageName) => {
    setPreviewImage(`../../api.winxto.top/uploads/${imageName}`);

  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  return (
    <>
    {loading ? (
      <Loader />
    ) : (
    <div className="bg-gray-900 ">
      <div className="px-8 pt-2">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <input
          id="search"
          name="search"
          value={searchQuery}
          onChange={handleSearch}
          type="number"
          placeholder="search here . . ."
          className="block w-[50vh] rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className="mt-8 flow-root">
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="z-10 mt-6 w-full whitespace-nowrap text-left">
              <thead className="border-b border-white/10 text-sm leading-6 text-white">
                <tr>
                  <th scope="col" className="py-2 pl-4 font-semibold sm:pl-6 lg:pl-8">
                    Name
                  </th>
                  <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
                    amount
                  </th>
                  <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
                    Status
                  </th>
                  <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
                  Recipt
                  </th>
                  <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
                    Request/Accept
                  </th>
                  <th scope="col" className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-white/5">
                {(searchQuery ? allDeposite : alldeposite )?.slice()
                          .reverse()
                          .map((item, index) => (
                  
                  <tr key={index}>
                    <td className="py-4 pl-4 sm:pl-6 lg:pl-8">
                      <div className="flex items-center">
                        <div className="truncate text-sm cursor-pointer  font-medium leading-6 text-white">
                        <Link to={`/admin/check/profile/${item?._id}`}>
                        {allusers?.find(user => user?.id === item?.user_id)?.email}
                        </Link>
                        </div>
                      </div>
                    </td>
                    <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                      <div className="">
                        <div className="font-mono text-sm leading-6 text-gray-400">
                          ${item?.amount} 
                        </div>
                      
                      </div>
                    </td>
                    <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                      <div className="">
                      <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                        {editMode && editableDeposite && editableDeposite.id === item?.id ? (
                            <select
                              id="status"
                              name="status"
                              className="border-0 px-3 py-1 placeholder-blueGray-300 focus:bg-white text-gray-600 bg-gray-200 rounded-sm text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                              type="text"
                              onChange={handleChange}
                              defaultValue={item?.status}
                            >
                              <option value="pending">Pending</option>
                              <option value="inprogress">In Progress</option>
                              <option value="decline">Decline</option>
                              <option value="complete">Complete</option>
                            </select>
                          ) : (
                            item?.status
                          )}

                                                  
                        </div>
                      </div>
                    </td>
                    <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-6 lg:pr-8">
                                <div className="flex items-center space-x-2">
                                  {item?.image_name && (
                                    <button
                                      onClick={() =>
                                        handleImageClick(item?.image_name)
                                      }
                                      className="text-blue-400 hover:underline"
                                    >
                                      {item?.image_name?.slice(0, 10)}
                                    </button>
                                  )}
                                </div>
                              </td>
                    <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                      <div className="">
                        <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                          {item?.createdAT} / {item?.acceptat}  
                        </div>
                      </div>
                    </td>
                    
                    <td className="py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                      <div className="z-0 flex space-x-4">
                        <Link to={`/user/profile/${item?.user_id}`}>
                            <FaEye
                              className="h-4 w-4 text-green-700 cursor-pointer"
                              title="Submit Changes"
                            />
                            </Link>
                        {editMode && editableDeposite?.id === item?.id ? (
                          <>
                            <FaCheck
                              className="h-4 w-4 text-green-700 cursor-pointer"
                              onClick={()=>handleSaveChange(item?.id)}
                              title="Submit Changes"
                            />
                            <FaTimes
                              className="h-4 w-4 text-red-700 cursor-pointer"
                              onClick={handleCancelEdit}
                              title="Cancel"
                            />
                          </>
                        ) : (
                          <>
                            <AiFillDelete
                              className="h-4 w-4 text-red-400 cursor-pointer"
                              onClick={() => handleDelete(item?.id)}
                              title="Delete"
                            />
                            <GrEdit
                              className="h-4 w-4 text-blue-400 cursor-pointer"
                              onClick={() => handleEdit(item)}
                              title="Edit"
                            />
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {previewImage && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70">
            <div className="relative">
              <img
                src={previewImage}
                alt="Preview"
                className="max-w-full max-h-screen object-contain"
              />
              <button
                onClick={handleClosePreview}
                className="absolute top-2 right-2 text-white text-xl bg-black p-2 rounded-full"
              >
                ×
              </button>
            </div>
          </div>
        )}
      {modalOpen && (
        <Confirmation
          isClose={isClose}
          deletefunction={deleteDeposite}
          id={deleteID}
        />
      )}
    </div>  )}</>
  );
}
